import Cookies from 'js-cookie';

// Store information about user origin.
export default () => {
  const rootDomain = location.hostname.split('.').slice(-2).join('.');

  if (!['apetitomenu.com', 'apetitomenu.test'].includes(rootDomain)) {
    return;
  }

  let userOrigin = {
    href: location.href || null,
    referrer: document.referrer || null,
  };

  try {
    Object.assign(
      userOrigin,
      JSON.parse(Cookies.get('_apetitomenu_user_origin')),
    );
  } catch (e) {
    //
  }

  try {
    Cookies.set('_apetitomenu_user_origin', JSON.stringify(userOrigin), {
      expires: 365,
      // root domain
      domain: `.${rootDomain}`,
    });
  } catch (e) {
    //
  }
};
